<template>
  <div>
    <div class="flex justify-between align-center mb-6">
      <h2 class="text-uppercase font-bold">Create {{ type }}</h2>
    </div>
    <div class="test-layout">
      <div>
        <ul class="md-sidebar_active_line pointer-events-none">
          <li v-for="(item, index) in steps" :key="index" >
            <router-link  @click.native="nextPage('current')" class="inline-flex py-2" :to="{name: item.routeName, query: {type: item.query}}">
              <svg class="h-5 w-5 md-acive_icon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 24 24">
                <path :fill=" item.complete ? '#00df05' : 'Current'" d="M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z M17.7,9.7l-7,7C10.5,16.9,10.3,17,10,17s-0.5-0.1-0.7-0.3l-3-3c-0.4-0.4-0.4-1,0-1.4s1-0.4,1.4,0l2.3,2.3l6.3-6.3c0.4-0.4,1-0.4,1.4,0S18.1,9.3,17.7,9.7z"  />
              </svg>
            <span class="md-acive_icon text-capitalize ml-2 text-base">{{ item.label }}</span>
            </router-link>
            <ul class="ml-6 md-sidebar_active_line" v-if="$route.name == 'quizCreation.chooseQuestion' && item.routeName == 'quizCreation.chooseQuestion'">
              <li v-for="( item, i) in quizTypes" :key="i" class="pointer">
                <a class="inline-flex py-1" @click="getQuestionBySubType(item.quiz_type_id),getIndex(i)" v-bind:class="isSubActiveId === item.quiz_type_id ? 'subnav_color' : ''">
                  <svg v-bind:class="isSubActiveId === item.quiz_type_id ? 'subSvg_color' : ''" class="h-4 w-4 md-acive_icon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 24 24">
                    <path fill="Current" d="M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z M17.7,9.7l-7,7C10.5,16.9,10.3,17,10,17s-0.5-0.1-0.7-0.3l-3-3c-0.4-0.4-0.4-1,0-1.4s1-0.4,1.4,0l2.3,2.3l6.3-6.3c0.4-0.4,1-0.4,1.4,0S18.1,9.3,17.7,9.7z"  />
                  </svg>
                  <span class="md-acive_icon text-capitalize ml-2 text-sm">{{ item.quiz_type_title }}</span>
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <div>
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>
<script>
import { findIndex } from 'lodash';
import data from '@/data/test-creation/data'
import { mapGetters, mapActions } from 'vuex';
export default {
  data() {
    return {
      showSub_id: 0,
      currentPage: '',
      types: data.types,
    }
  },
  computed: {
    ...mapGetters({
      quizTypes: "question/getQuiz",
      getSectionWiseQuiz: "question/getSectionWiseQuiz",
      getSelectedQuizTypeId: "question/getSelectedQuizTypeId",
    }),
    type() {
      return this.$route.query.type
    },
    steps() {
      return data.QuizSteps.map(item => {
        item.query = this.type;
        return item;
      })
    },
    isSubActiveId() {
      return this.getSelectedQuizTypeId;
    }
  },
  methods: {
    ...mapActions({
      actSectionWiseQuiz: "question/actSectionWiseQuiz",
    }),
    nextPage(page = 'next') {
     let index = findIndex(this.steps, ['routeName', this.$route.name]);
      if(index !== this.steps.length - 1 && page != 'current') {
        let routeName = this.steps[index + 1].routeName;
        this.$router.push({
          name: routeName
        })
      }
    },
    getIndex(i){
      this.showSub_id = i;
      console.log(this.showSub_id,'this.showSub_id')
    },
    getQuestionBySubType(quizTypeId) {  
      let testData = JSON.parse(localStorage.getItem('quizQuestionCount'));
      // console.log(quizTypeId,'quizTypeId',testData.negative_marking)
      this.actSectionWiseQuiz(`?quiz_type_id=${quizTypeId}&negative_marking=${testData.negative_marking}`);
      localStorage.setItem("quiz_type_id",quizTypeId);
    }
  },
  mounted() {
    this.nextPage('current');
  },
  created(){

  }
}
</script>
<style lang="scss">
.test-layout {
  display: grid;
  grid-template-columns: minmax(150px, 25%) 1fr;
  a {
    color: rgb(155, 155, 155) !important;
    &.router-link-active {
      font-weight: 600;
    }
  }

}
.subnav_color span{
  color: #7432b8 !important;
}
.subSvg_color{
  fill: #7432b8 !important;
}
</style>
